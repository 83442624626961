/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { ChangeEvent, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Box,
  Grid,
  TextField,
  Typography,
  Divider,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  GetChangeDto,
  ListWorkPackageDto,
  ProcessListForChangeDto,
  UpdateChangeDtoStatusEnum,
} from '@codefluegel/zeta-change-typescript-client';
import ConstructionIcon from '@mui/icons-material/Construction';
/**
 * The internal dependencies
 */
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import theme from '../../theme';
import Tag from '../Tag/Tag';
import {
  getTotalTime,
  getSumOfWP,
  formatNumber,
} from '../../utils/getTotalTime';
import LoadSpinnerSmall from '../LoadSpinner/LoadSpinnerSmall';
import { getDepartmentById } from '../../utils/utils';
import { useProjectStore } from '../../store/projectStore';
import { isChangeManager } from '../../utils/permissionHelper';

type ProcessCardProps = {
  process: ProcessListForChangeDto;
  workPackages: ListWorkPackageDto[];
  change: GetChangeDto | null;
  handleChangeProcess: (fieldName: string, value: number, id: number) => void;
  handleActiveProcess: (id: number) => void;
  editable: boolean;
  isActive: boolean;
  isLoading: boolean;
};

const ProcessTable = observer(
  ({
    process,
    workPackages,
    change,
    isActive,
    isLoading,
    handleChangeProcess,
    handleActiveProcess,
    editable,
  }: ProcessCardProps) => {
    const { pspPhases, pspWorkPackages } = useProjectStore();
    const { t } = useTranslation();
    const [processHours, setProcessHours] = useState(
      process.calculatedHours
        ? process.calculatedHours
        : getTotalTime(workPackages),
    );

    const department = getDepartmentById(
      process.pId,
      change?.commonProject?.navProcessToCommonProjectRelations,
    );

    const isManager = isChangeManager(change);

    const isFlightIncluded =
      workPackages && workPackages.some(wP => wP.travelIncluded);

    const showReleasedHours =
      change?.status === UpdateChangeDtoStatusEnum.CalcComplete ||
      change?.status === UpdateChangeDtoStatusEnum.CrWorkpackagesInProgress ||
      change?.status ===
        UpdateChangeDtoStatusEnum.CrWorkpackagesInInternalProgress ||
      change?.status ===
        UpdateChangeDtoStatusEnum.ExternalChangeInternallyApproved ||
      change?.status === UpdateChangeDtoStatusEnum.Check ||
      change?.status === UpdateChangeDtoStatusEnum.Closed ||
      change?.status === UpdateChangeDtoStatusEnum.InternalApprovalRejected;

    const editReleasedHours =
      change?.status === UpdateChangeDtoStatusEnum.CalcComplete ||
      change?.status === UpdateChangeDtoStatusEnum.CrWorkpackagesInProgress ||
      change?.status ===
        UpdateChangeDtoStatusEnum.CrWorkpackagesInInternalProgress ||
      change?.status ===
        UpdateChangeDtoStatusEnum.ExternalChangeInternallyApproved;

    const onChange = (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      const { target } = e;
      const { value } = target;
      handleActiveProcess(process.id);
      if (target.name === 'calculatedHours') setProcessHours(Number(value));

      handleChangeProcess(target.name, Number(value), process.id);
    };

    const getWorkPackagePhase = (workPackage: ListWorkPackageDto) =>
      pspPhases.find(p => p.id === workPackage.navPhasePspId);

    const getWorkPackageName = (wp: ListWorkPackageDto): string => {
      const pspWP = pspWorkPackages.find(pspwp => pspwp[wp.navPhasePspId]);
      if (pspWP) {
        const pspPackage = pspWP[wp.navPhasePspId].find(
          elem => elem.navPhasePspId === wp.navPhasePspId,
        );
        return (pspPackage && pspPackage.name) || '';
      }
      return '';
    };
    return (
      <Accordion
        css={css`
          width: 100%;
          background-color: white;
          margin-bottom: 16px;

          &::before {
            display: none;
          }

          & .MuiAccordionSummary-gutters {
            padding-left: 10px;
          }
          &.Mui-expanded {
            margin: 0;
            margin-bottom: 16px;
          }
        `}
        elevation={3}
      >
        <AccordionSummary
          aria-controls="panel1-content"
          id="panel1-header"
          expandIcon={
            <ExpandMoreIcon
              color="primary"
              css={css`
                transform: rotate(-90deg);
              `}
            />
          }
          css={css`
            flex-direction: row-reverse;
            gap: 16px;
            height: 70px;
            align-items: center;

            & .MuiAccordionSummary-content ~ .Mui-expanded {
              transform: rotate(90deg) !important;
            }
          `}
        >
          <Box
            css={css`
              display: flex;
              align-items: center;
              gap: 13px;
              justify-content: flex-end;
              max-width: fit-content;
              padding: 7px 7px;
            `}
          >
            <Typography
              variant="h4"
              css={css`
                flex-shrink: 0;
                justify-self: flex-start;
                height: max-content;
                margin-right: 20px;
                margin-bottom: 1px;
              `}
            >
              {department?.name || ''}
            </Typography>

            {process.timeImpact && (
              <AccessTimeIcon sx={{ color: theme.palette.primary.light }} />
            )}
            {process.plannedMaterial !== '' && (
              <ConstructionIcon sx={{ color: theme.palette.primary.light }} />
            )}

            {isFlightIncluded && (
              <FlightTakeoffIcon
                css={css`
                  color: ${theme.palette.primary.light};
                `}
              />
            )}

            <Typography
              variant="h6"
              css={css`
                font-size: 14px;
                font-weight: 300;
              `}
            >
              {workPackages.length || 0} {t('workpackages')}
            </Typography>

            <Tag label={`${getTotalTime(workPackages)}h ${t('required')}`} />
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {isLoading && isActive && <LoadSpinnerSmall />}

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    {t('workpackages')}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} align="right">
                    {t('hourlyRate')} [€/h]
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} align="right">
                    {t('estimatedHoursKTM')} [h]
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} align="right">
                    {t('costs')} [€]
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workPackages.map(wp => (
                  <TableRow
                    key={wp.navPhasePspId}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {`${getWorkPackagePhase(wp)?.name} — ${getWorkPackageName(
                        wp,
                      )} — ${wp.workPackageNo} `}
                    </TableCell>
                    <TableCell align="right">
                      {department?.hourlyRate}
                    </TableCell>
                    <TableCell align="right">{wp.hours}</TableCell>
                    <TableCell align="right">
                      {formatNumber(
                        (wp.hours || 0) * (department?.hourlyRate || 0),
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow sx={{ borderTop: '2px solid grey' }}>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    {t('sumeval')}
                  </TableCell>
                  <TableCell />
                  <TableCell sx={{ fontWeight: 'bold' }} align="right">
                    {getTotalTime(workPackages)}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} align="right">
                    {formatNumber(
                      getSumOfWP(workPackages, department?.hourlyRate || 0),
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Box>
            <Typography sx={{ padding: 1 }} variant="h5">
              {t('calculatedHours')}
            </Typography>

            <Grid container spacing={2} sx={{ paddingRight: 2 }}>
              <Grid item xs={4}>
                <TextField
                  css={css`
                    width: 100%;
                    margin-top: 16px;
                    .MuiInput-underline:before {
                      border-bottom: 1px solid #ececec;
                    }
                    .MuiInput-input {
                      padding: 4px 0 4px;
                    }
                    .MuiInputLabel-asterisk {
                      color: #ff0000;
                    }
                  `}
                  required
                  name="calculatedHours"
                  type="number"
                  defaultValue={processHours}
                  disabled={
                    !(
                      change?.status ===
                        UpdateChangeDtoStatusEnum.EvalAccepted ||
                      change?.status ===
                        UpdateChangeDtoStatusEnum.EvalCalcRejected
                    ) || !editable
                  }
                  label={t('HoursCalc')}
                  variant="standard"
                  onChange={onChange}
                  InputProps={{
                    style: {
                      paddingLeft: '16px',
                      paddingBottom: '6px',
                      marginBottom: '16px',
                      fontSize: '14px',
                      fontWeight: '300',
                    },
                    endAdornment: (
                      <InputAdornment position="end">h</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: {
                      paddingLeft: '20px',
                      fontWeight: 300,
                      color: '#333333',
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
              >
                <Typography> {department?.hourlyRate} €/h</Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
              >
                <Typography sx={{ fontWeight: 'bold' }}>
                  {formatNumber(
                    (process.calculatedHours || 1) *
                      (department?.hourlyRate || 0),
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {change && showReleasedHours && (
            <Box>
              <Typography sx={{ padding: 1 }} variant="h5">
                {t('customerreleasedHours')}
              </Typography>

              <Grid container spacing={2} sx={{ paddingRight: 2 }}>
                <Grid item xs={4}>
                  <TextField
                    css={css`
                      width: 100%;

                      .MuiInput-underline:before {
                        border-bottom: 1px solid #ececec;
                      }
                      .MuiInput-input {
                        padding: 4px 0 4px;
                      }
                      .MuiInputLabel-asterisk {
                        color: #ff0000;
                      }
                    `}
                    name="releasedHours"
                    required
                    disabled={!isManager || !editReleasedHours}
                    defaultValue={process.releasedHours}
                    type="number"
                    label={t('releasedHours')}
                    variant="standard"
                    onBlur={onChange}
                    InputProps={{
                      style: {
                        paddingLeft: '16px',
                        paddingBottom: '6px',
                        marginBottom: '16px',
                        fontSize: '14px',
                        fontWeight: '300',
                      },
                      endAdornment: (
                        <InputAdornment position="end">h</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      style: {
                        paddingLeft: '20px',
                        fontWeight: 300,
                        color: '#333333',
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                  }}
                >
                  <Typography> {department?.hourlyRate} €/h</Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {formatNumber(
                      (process.releasedHours || 1) *
                        (department?.hourlyRate || 0),
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    );
  },
);

export default ProcessTable;
